"use client";

import { type FC } from "react";
import { Input } from "../ui/input";
import { GhostButton } from "../button/ghost-button";
import { Minus, Plus, TrashSimple } from "@phosphor-icons/react/dist/ssr";

type Props = {
  qty: number,
  onChange: (newQty: number) => void;
  disabled?: boolean,
  className?: string,
}

export const ProductQtyInput: FC<Props> = function ({ qty, onChange, className = '', disabled }) {
  return <div className={`w-[6em] relative ${className}`}>
    <GhostButton size="xs" attrs={{ type: "button" }} disabled={disabled} className="absolute inset-y-0 left-0 rounded-r-none px-3" onClick={() => onChange(qty <= 0 ? 0 : qty - 1)}>
      {qty > 1 ? <Minus /> : <TrashSimple className="text-error" />}
    </GhostButton>

    <Input
      type={"number"}
      className="text-center"
      readOnly
      disabled={disabled}
      value={qty.toFixed(0)}
      onChange={(v) => {
        const parsed = parseInt(v.target.value);
        if (isNaN(parsed)) return;
        onChange(parsed);
      }}
    />

    <GhostButton size={"xs"} attrs={{ type: "button" }} disabled={disabled || qty >= 3} className="absolute inset-y-0 right-0 rounded-l-none px-3" onClick={() => onChange(qty > 2 ? 3 : qty + 1)}>
      <Plus />
    </GhostButton>
  </div>
};