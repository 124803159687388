import Link from 'next/link';
import { formatCenterUrl, formatCenterName } from '@repo/utils';
import type { FC, ReactNode } from 'react';
import { CartTotal } from './cart-total';
import { CouponForm } from './coupon-form';
import { ProductLine } from './product-line';
import { LoadingOverlay } from '../loading/loading-overlay';
import { FilledButton } from '../button/filled-button';
import { useCart, useShowCouponForm } from '@web/hooks/cart';
import { AppliedCoupon } from './applied-coupon';

type Props = {
  /**
   * If set to true, the totals will 
   * include all detailed informations
   */
  detailedTotals?: boolean;

  /**
   * Allow to choose which controls to display
   * in the cart
   */
  controls?: {
    cartSelector?: boolean;
    checkoutButton?: boolean;
  };
  minimal?: boolean;

  /**
   * React Component to render when the cart is empty
   */
  onCartEmpty?: ReactNode
} & ({ cartId?: string; } | { useCartData: ReturnType<typeof useCart> });

/**
 * Renders the actual cart content 
 * 
 * @returns 
 */
export const Cart: FC<Props> = (props) => {
  const { data: cart, isLoading } = useCart();
  const showCouponForm = useShowCouponForm();

  // If no cart yet
  if (!cart || cart.lines.length < 1) {
    // If it's loading, show loading indicator
    if (isLoading)
      return (<LoadingOverlay show />);

    // If no cart provided
    return (
      <div className="py-16 text-center">
        {props.onCartEmpty ?? <p className="text-center text-light">
          Vous n&apos;avez aucun box
          <br /> dans votre panier
        </p>}
      </div>
    );
  }


  return (
    <>
      {/* Introduction */}
      <div className="mt-2 text-sm text-gray-500">
        <p>
          Votre panier pour le centre{' '}
          <Link
            href={formatCenterUrl(cart.center.path!)}
            className="font-bold text-brand"
            target={"_blank"}
          >
            {formatCenterName(cart.center)}
          </Link>
          .
        </p>
      </div>

      <div className={'relative -mx-6 overflow-hidden px-6'}>
        {/* Loading indicator */}
        <LoadingOverlay show={isLoading} />

        {/* Product items */}
        <ul className="flex flex-col gap-4 my-4">
          {cart.lines.map((line) => (
            <li key={line.id!}>
              <ProductLine
                cartId={cart.id}
                line={line}
              />
            </li>
          ))}
        </ul>

        {/* Coupons */}
        <div className='mt-8'>
          {cart.coupon
            ? <AppliedCoupon coupon={cart.coupon} taxRate={cart.lines[0].box.taxRate} />
            : <>{showCouponForm && <CouponForm />}</>
          }
        </div>

        {/* Total */}
        <CartTotal
          total={cart.total.ttc}
          discountsTotal={cart.discountsTotal?.ttc}
          detailed
          className="my-6"
        />

        {props.controls?.checkoutButton && (
          <Link href={`/commande?cartId=${cart.id}`}>
            <FilledButton fullWidth>
              Passer commande
            </FilledButton>
          </Link>
        )}
      </div>
    </>
  );
};
