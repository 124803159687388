"use client;"
import type { API } from "@web/trpc/types";
import type { FC } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getLocalCartId } from "@web/hooks/cart";
import { trpc } from "@web/trpc/client";
import { toast } from "sonner";
import { AppliedCouponLayout } from "./applied-coupon-layout";

type Props = {
  coupon: NonNullable<API.Cart['coupon']>,
  taxRate: number,
}

export const AppliedCoupon: FC<Props> = function ({ coupon, taxRate }) {
  const queryClient = useQueryClient();

  const removeCoupon = useMutation({
    mutationKey: ['removeCoupon'],
    mutationFn: async () => {
      const cartId = getLocalCartId();
      if (!cartId) return;

      await trpc.cart.removeCoupon.mutate({ cartId });
      toast.success("La promotion a bien été retirée");

      queryClient.resetQueries({ queryKey: ['cart'] });
    }
  })

  const isLoading = removeCoupon.isPending;

  return <AppliedCouponLayout
    coupon={coupon}
    isLoading={isLoading}
    taxRate={taxRate}
    deleteBtn={<button disabled={isLoading} type="button" className="text-error hover:underline disabled:text-light text-sm py-1" onClick={() => removeCoupon.mutate()}>
      Retirer
    </button>}
  />
};