import { convertNumberToDisplayablePriceString } from '@repo/utils';
import { type FC } from 'react';

type Props = {
  total: number;
  discountsTotal?: number
  detailed?: boolean;
  className?: string;
};

export const CartTotalLine: FC<{
  total: number;
  label: string;
  className?: string;
}> = function ({ total, label, className }) {
  return (
    <div className={`${className} flex flex-row items-center justify-between gap-4`}>
      <div className="flex-1">{label}</div>

      <div className="text-right">
        {convertNumberToDisplayablePriceString(total)} €
      </div>
    </div>
  );
};

export const CartTotal: FC<Props> = function ({ total, discountsTotal, className, detailed }) {
  const showDetailed = detailed && discountsTotal !== undefined;

  return (
    <div
      className={`${className} space-y-2 rounded-lg bg-light-grey p-4 text-brand-black`}
    >
      {showDetailed && (
        <>
          {discountsTotal > 0 && (
            <CartTotalLine
              total={discountsTotal}
              label={'Réductions'}
              className="text-sm"
            />
          )}
        </>
      )}

      <CartTotalLine
        total={total}
        label={'Total'}
        className={`font-semibold`}
      />
    </div>
  );
};
