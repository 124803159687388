'use client';

/* eslint-disable react-hooks/exhaustive-deps */
import { type ReactNode, type FC } from 'react';
import Link from 'next/link';
import {
  renderProductName,
  renderProductUrl,
} from '../../utils';
import { Price } from '../price';
import { useCart, useCartLine } from '@web/hooks/cart';
import type { API } from '@web/trpc/types';
import { ProductQtyInput } from './product-qty-input';
import Image from 'next/image';
import { ProductQtyBadge } from './product-qty-badge';
import { InputErrorMessage } from '../form/input-error-message';
import { ProductTooltip } from '../products/product-tooltip';

type Props = {
  cartId: string;
  line: API.CartLine;
  onClick?: () => void,
};

const LineClickableElement: FC<{
  children: ReactNode,
  className?: string,
  onClick?: () => void,
  href: string
}> = ({ onClick, href, ...props }) => {
  if (!onClick)
    return <Link href={href} target='_blank' {...props} />;

  return <button type="button" onClick={onClick} {...props} />;
}

export const ProductLine: FC<Props> = function (props) {
  const { handleQtyChange, loading, error } = useCartLine(props.cartId, props.line);
  const { isFetching } = useCart();

  return (
    <div className="w-full flex gap-4">
      <LineClickableElement
        href={renderProductUrl(props.line.box.path)}
        className='flex items-center relative rounded-lg bg-light-grey'
        onClick={props.onClick}
      >
        <ProductQtyBadge className="absolute right-1 top-1">{props.line.qty}</ProductQtyBadge>
        <Image
          src={props.line.box.cover.url}
          width={80} height={80}
          className="object-contain object-center aspect-square"
          alt={props.line.box.cover.alt}
        />
      </LineClickableElement>

      <div className='flex-1 h-full flex flex-col justify-between'>
        {/* Name */}
        <LineClickableElement
          href={renderProductUrl(props.line.box.path)}
          className="transition-colors text-sm font-medium hover:text-brand flex justify-between items-center"
          onClick={props.onClick}
        >
          <span>{renderProductName(props.line.box)}</span>
        </LineClickableElement>

        {/* Linked product */}
        <ul className='text-xs py-1 space-y-1'>
          {/* Unit price */}
          <li className='flex justify-between items-center text-light'>
            <span>{props.line.box.discountedPrice?.ttc !== undefined
              ? <>Le 1<span className="align-super text-[0.8em]">er</span> mois</>
              : <>Prix mensuel</>}
            </span>

            <Price
              inline
              price={props.line.box.price.ttc}
              discountedPrice={props.line.box.discountedPrice?.ttc}
            />
          </li>

          {/* Booking fees */}
          <li className='flex justify-between items-center text-light'>
            <div>
              <ProductTooltip tooltip={props.line.bookingFees.tooltip}>
                Frais de dossier
              </ProductTooltip>
            </div>
            <span>
              <Price
                inline
                price={props.line.bookingFees.price.ttc}
                discountedPrice={props.line.bookingFees.discountedPrice?.ttc}
                freeLabel='Offerts'
              />
            </span>
          </li>

          {/* Rent in advance */}
          {props.line.rentInAdvance && <li className='flex justify-between text-light'>
            <div>
              <ProductTooltip tooltip={props.line.rentInAdvance.tooltip}>
                Loyer d&apos;avance
              </ProductTooltip>
            </div>
            <span>
              <Price
                inline
                price={props.line.rentInAdvance.price.ttc}
                discountedPrice={props.line.rentInAdvance.discountedPrice?.ttc}
                freeLabel='Offert'
              />
            </span>
          </li>}
        </ul>
        {/* /Linked product */}

        {/* Subtotal & quantity selector */}
        <div className="flex flex-row items-center justify-between gap-4">
          <ProductQtyInput qty={props.line.qty} onChange={handleQtyChange} disabled={loading || isFetching} />

          <div className="text-brand-black text-lg font-semibold">
            <Price
              price={props.line.total.ttc}
            />
          </div>
        </div>
        {/* /Subtotal & quantity selector */}

        {/* Errors */}
        {error && <InputErrorMessage>{error}</InputErrorMessage>}
      </div>
    </div>
  );
};

export const ProductLineSkeleton: FC = function () {
  return <div className="w-full flex gap-4 animate-pulse">
    {/* Image */}
    <div className='rounded-lg bg-light-grey w-[80px]' />

    <div className='flex-1 h-full flex flex-col justify-between'>
      {/* Name */}
      <div
        className="bg-light-grey text-sm rounded-lg w-1/2 h-5"
      />

      {/* Linked product */}
      <ul className='text-xs py-1 space-y-1'>
        {/* Unit price */}
        <li className='bg-light-grey rounded-lg w-full h-4' />

        {/* Booking fees */}
        <li className='bg-light-grey rounded-lg w-full h-4' />
      </ul>
      {/* /Linked product */}

      {/* Subtotal & quantity selector */}
      <div className="flex flex-row items-center justify-between gap-4">
        {/* Qty input */}
        <div className="bg-light-grey rounded-lg w-[96px] h-10" />

        {/* Subtotal */}
        <div className="bg-light-grey rounded-lg flex-1 h-10" />
      </div>
      {/* /Subtotal & quantity selector */}

    </div>
  </div>;
}