import type { API } from "@web/trpc/types";
import type { FC, ReactNode } from "react";
import { InputLabel } from "../form/input-label";

type Props = {
  coupon: NonNullable<API.Cart['coupon']>,
  taxRate: number,
  isLoading?: boolean,
  deleteBtn?: ReactNode,
}

export const AppliedCouponLayout: FC<Props> = function ({ coupon, isLoading, deleteBtn, taxRate }) {
  const size = 8;
  const bgColor = 'var(--color-light-grey)';

  return <div>
    {/* Remove coupon */}
    <div className="flex items-center justify-between gap-4">
      <InputLabel>Réduction appliquée</InputLabel>
      {deleteBtn}
    </div>

    {/* Coupon */}
    <div className={`relative bg-light-grey w-full py-3 px-6 rounded-lg clip-coupon flex justify-between items-center gap-4 ${isLoading ? 'animate-pulse': ''}`}>
      <div>
        <p className="text-xs rounded-full inline-block uppercase font-bold mb-1">Réduction</p>
        {/* Quick fix décidé en réunion, sur les Coupon fixed, on fait le discountAmount HT * le tax rate du premier box du panier */}
        <p className="text-3xl font-extrabold">-{coupon.discountType === 'FIXED'
          ? <>{(coupon.discountAmount * (1 + taxRate / 100)).toFixed(2)}€</>
          : <>{coupon.discountAmount}%</>
        }</p>
        <p className="text-sm">{coupon.description}</p>
      </div>

      <p className="font-mono text-sm tracking-widest border-l-2 pl-2 border-black/50 border-dashed" style={{ writingMode: "vertical-lr" }}>{coupon.code}</p>
    </div>

    <style>
      {`.clip-coupon {
    background: 
      radial-gradient(circle at 0%, transparent, transparent ${size}px, ${bgColor} ${size}px, ${bgColor} 50%, transparent 50%),
      radial-gradient(circle at 100%, transparent, transparent ${size}px, ${bgColor} ${size}px, ${bgColor} 70%, transparent 70%);
  }`}
    </style>
  </div>;
};