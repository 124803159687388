import { type FC, type ReactNode } from "react";
import { ControlledDialog, DialogContent, DialogTitle, DialogTrigger } from "../ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";

type Props = {
  title?: ReactNode,
  description?: ReactNode,
  children: ReactNode | ((closeCallback: () => void) => ReactNode),
  actions?: (closeCallback: () => void) => ReactNode[],
  trigger: (openCallback: () => void) => ReactNode,
  onClose?: () => void,
  closableOverlay?: boolean,
  initiallyOpen?: boolean,
}

export const Modal: FC<Props> = function ({ children, trigger, title, description, actions, closableOverlay = false, initiallyOpen = false, onClose }) {
  return <ControlledDialog dismissible={closableOverlay} defaultOpen={initiallyOpen}>
    {(controller) => <>
      <DialogTrigger asChild>{trigger(controller.open)}</DialogTrigger>

      <DialogContent position='center'>
        {title && <DialogTitle asChild className="text-lg text-pretty font-medium leading-6 text-gray-900">
          {title}
        </DialogTitle>}

        <DialogDescription asChild>
          {typeof children === 'function'
            ? children(() => {
              controller.close();
              onClose?.();
            })
            : children
          }
        </DialogDescription>

        {actions && <ul className="mt-8 flex flex-row items-center justify-end space-x-8">
          {actions(() => {
            controller.close();
            onClose?.();
          }).map((Action, index) => <li key={index}>{Action}</li>)}
        </ul>}
      </DialogContent>
    </>
    }
  </ControlledDialog>
}
