
import { type FC, useId, useRef } from 'react';
import { GhostButton } from '../button/ghost-button';
import { LoadingIndicator } from '../loading/loading-indicator';
import { InputLabel } from '../form/input-label';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getLocalCartId } from '@web/hooks/cart';
import { trpc } from '@web/trpc/client';
import { toast } from 'sonner';
import { TrpcErrorHandler } from '@web/trpc/trpc-error-handler';
import { TextInput } from '../form/text-input';

type Props = {
  loading?: boolean;
};

export const CouponForm: FC<Props> = function ({ loading: externalLoading = false }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const id = useId();

  const mutation = useMutation({
    mutationKey: ["applyCoupon"],
    mutationFn: async (couponCode: string) => {
      const cartId = getLocalCartId();
      
      if (!cartId || couponCode.length < 1) return ;

      return await trpc.cart.applyCoupon.mutate({
        code: couponCode.toUpperCase(),
        cartId,
      }).then(() => {
        // If its OK we invalidate the cart and show a success toast
        toast.success(<span>
          Le code promo &quot;<span className='font-mono'>{couponCode.toUpperCase()}</span>&quot; a été appliqué avec succès !
        </span>);

        queryClient.resetQueries({ queryKey: ['cart'] });

        return true;
      }).catch((e) => {
        // If an error occured we show an error message
        const error = TrpcErrorHandler.parseError(e);
        toast.error(error.message);
        return false;
      });
    },
  });

  
  const handleSubmit = () => {
    if (!inputRef.current || mutation.isPending) return;
    
    const code = inputRef.current?.value;
    mutation.mutateAsync(code)
    .then((res) => res === true && inputRef.current ? inputRef.current.value = "" : "");
  }
  
  const loading = externalLoading || mutation.isPending;

  return (
    <div>
      <InputLabel htmlFor={`coupon-code-input-${id}`}>
        Appliquer un code promotionnel
      </InputLabel>
      
      <div className="relative">
        <TextInput
          ref={inputRef}
          id={`coupon-code-input-${id}`}
          placeholder="Code..."
          className='font-mono'
          disabled={loading}
          onKeyDown={(e) => { 
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            } 
          }}
        />

        <GhostButton
          disabled={loading}
          size={"xs"}
          attrs={{ type: "button" }}
          onClick={handleSubmit}
          className="absolute right-0 inset-y-0 text-xs tracking-wide px-4 uppercase rounded-l-none flex items-center gap-2">
          Appliquer {loading && <LoadingIndicator className='!text-brand !m-0' />}
        </GhostButton>
      </div>
    </div>
  );
}