import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import type { AppRouter } from "@api/trpc/trpc";
import { getApiUrl } from "@web/lib/utils";

export const trpc = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${getApiUrl()}trpc`,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          cache: 'no-store'
        })
      }
    }),
  ],
});