import { cn } from '@web/lib/utils';
import { type FC, type ReactNode } from 'react';

export type InputLabelProps = {
  /**
   * Permet de spécifier un ID HTML d'un input pour le lier au label. 
   * Pour générer un ID automatiquement, il est possible d'utiliser le hook natif `useId()`.
   * 
   * Si cette propriété n'est pas définie, alors le label sera rendu comme un <span>
   */
  htmlFor?: string;
  /**
   * Le contenu du label, qui peut être un Node React
   */
  children?: ReactNode | string;
  /**
   * Si le label est marqué comme requis, alors une étoile rouge sera affichée à sa place 
   */
  required?: boolean;
  /**
   * Classes CSS optionnelles
   */
  className?: string;
};

/**
 * Label d'input.
 */
export const InputLabel: FC<InputLabelProps> = (props) => {
  const Wrapper = props.htmlFor !== undefined ? 'label' : 'div';

  return <Wrapper
    htmlFor={props.htmlFor}
    className={cn("cursor-pointer text-sm font-semibold mb-1 flex items-center", props.className)}
  >
    {props.children} {props.required && <span className="text-red-500">*</span>}
  </Wrapper>;

};