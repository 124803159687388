"use client";

import type { ReactNode, FC, ComponentProps } from "react";
import { Heading } from "../heading";
import { GhostButton } from "../button/ghost-button";
import { Info } from "@phosphor-icons/react/dist/ssr/Info";
import { Modal } from "../modals/modal";


type Props = {
  children: ReactNode,
  iconWeight?: ComponentProps<typeof Info>['weight']
  tooltip: null | {
    title: string,
    content: string,
  }
}


export const ProductTooltip: FC<Props> = function({children, tooltip, iconWeight}) {
  if (!tooltip) return children

  return (
    <Modal
      closableOverlay={true}
      trigger={(open) => (
        <button
          type="button"
          className={'flex flex-row items-center hover:underline'}
          onClick={open}>
          {children} <Info weight={iconWeight} className="ml-1 text-inherit" />
        </button>
      )}
      title={
        <Heading as="h2" level={3} className="mb-6">
          {tooltip.title}
        </Heading>
      }
      actions={(close) => [
        <GhostButton key={1} onClick={close}>
          Fermer
        </GhostButton>,
      ]}>
      <div
        className="prose-white prose"
        dangerouslySetInnerHTML={{ __html: tooltip.content }}
      />
    </Modal>
  );
};