import type { ComponentProps } from "react";
import { forwardRef, useId } from "react";
import type { InputProps } from "./types";
import { InputLabel } from "./input-label";
import { InputErrorMessage } from "./input-error-message";

type Props = Omit<ComponentProps<"input">, 'onChange'> & InputProps<string> & {
  inputClassName?: string;
};

const TextInput = forwardRef<HTMLInputElement, Props>(function ({inputClassName, ...props}, ref) {
  const id = useId();

  return <div className={props.className}>
    {props.label && <InputLabel htmlFor={id} required={props.required}>{props.label}</InputLabel>}
    <input
      {...props}
      ref={ref}
      required={false}
      onChange={(e) => props.onChange?.(e.target.value)}
      id={props.id ?? id}
      className={`flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-backround file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 ${props.className} ${inputClassName}`}
    />
    {props.error && <InputErrorMessage>{props.error}</InputErrorMessage>} 
  </div>;
});

TextInput.displayName = "TextInput";
export { TextInput };