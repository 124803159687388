import { debounce } from "lodash";
import { type ReactNode, type FC } from "react";
import { Heading } from "../heading";
import { Cart } from "./cart";
import { reportViewCart } from "@web/lib/analytics";
import { ControlledDialog, DialogContent, DialogTitle } from "../ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";


type Props = {
  /**
   * Used to render a button that controls 
   * the open state of the modal
   * 
   * @param params 
   * @returns 
   */
  trigger: (params: {
    /**
     * Opens the cart modal.
     * @returns {void}
     */
    openModal: () => void,

    /**
     * Closes the cart modal.
     * @returns {void}
     */
    closeModal: () => void,

    /**
     * Indicates the state of the cart modal.
     */
    isOpen: boolean
  }) => ReactNode;
};

/**
 * The mini cart modale that appears in the navigation bar.
 *
 * @returns 
 */
export const CartModal: FC<Props> = ({ trigger }) => {
  // const [isOpen, setIsOpen] = useState(false);
  // console.log(isOpen);

  // const closeModal = debounce(() => {
  //   setIsOpen(false);
  // }, 100);

  // const openModal = debounce(() => {
  //   setIsOpen(true);
  //   reportViewCart();
  // }, 100);

  return (
    <ControlledDialog>
      {(controller) => (
        <>
          {trigger({ 
            isOpen: controller.isOpen, 
            openModal: controller.open, 
            closeModal: () => {
              reportViewCart();
              controller.close();
            } 
          })}

          <DialogContent position={'top-right'}>
            {/* Title  */}
            <DialogTitle asChild>
              <Heading as={'h2'} level={3}>
                Mon panier
              </Heading>
            </DialogTitle>

            <DialogDescription hidden>
              Popup affichant votre panier
            </DialogDescription>

            <Cart controls={{ checkoutButton: true }} />
          </DialogContent>
        </>
      )}
    </ControlledDialog>
  );
};