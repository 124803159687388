/* eslint-disable no-empty */
/* eslint-disable no-case-declarations */
import { TRPCClientError } from "@trpc/client";
import type { AppRouter } from "@api/trpc/trpc";
import type { ZodIssue } from "zod";
import type { TRPC_ERROR_CODE_KEY } from "@trpc/server/rpc";

export interface ValidationError {
  field: string;
  message: string;
}

export interface ApiError {
  code: TRPC_ERROR_CODE_KEY | 'UNKNOWN_ERROR';
  message: string;
  cause?: unknown;
  errors?: ZodIssue[],
}

// Error handler utility
export class TrpcErrorHandler {
  static isTRPCClientError(error: unknown): error is TRPCClientError<AppRouter> {
    return error instanceof TRPCClientError;
  }

  static parseError(error: unknown): ApiError {
    if (this.isTRPCClientError(error)) {
      // Handle tRPC specific errors
      const trpcError = error as TRPCClientError<AppRouter>;

      // Parse based on error code
      switch (trpcError.data?.code) {
        case 'BAD_REQUEST':
          const payload: ApiError = {
            code: 'BAD_REQUEST',
            message: trpcError.message,
          };

          try {
            payload.errors = JSON.parse(trpcError.message);
            payload.message =  "Les données transmises ne semblent pas correctes";
          } catch (_) {}

          return payload;
        case 'UNAUTHORIZED':
          return {
            code: 'UNAUTHORIZED',
            message:  trpcError.message || 'Vous devez être authentifié pour effectuer cette action',
          };
        case 'FORBIDDEN':
          return {
            code: 'FORBIDDEN',
            message:  trpcError.message || 'Vous n\'êtes pas autorisé à effectuer cette action',
          };
        case 'NOT_FOUND':
          return {
            code: 'NOT_FOUND',
            message: trpcError.message || 'La ressource n\'existe pas',
          };
        default:
          return {
            code: 'INTERNAL_SERVER_ERROR',
            message: 'Une erreur innatendue s\'est produite',
            cause: {
              internalMessage: trpcError.message,
              ...trpcError.cause
            },
          };
      }
    }

    // Handle non-tRPC errors
    if (error instanceof Error) {
      return {
        code: 'UNKNOWN_ERROR',
        message: error.message,
        cause: error,
      };
    }

    return {
      code: 'UNKNOWN_ERROR',
      message: 'An unknown error occurred',
      cause: error,
    };
  }

  static handleNotFound(e: unknown, callback: () => void) {
    if (TrpcErrorHandler.parseError(e).code === "NOT_FOUND") {
      callback();
    }
  }
}