import { type ReactNode, type FC } from "react";

type Props = {
  className?: string,
  children: ReactNode,
  hidden?: boolean
}

export const ProductQtyBadge: FC<Props> = function({children, className = '', hidden}) {
  return <span className={`block text-white bg-brand rounded-full text-[10px] font-mono font-bold text-center size-[16px] py-px ${className} transition-transform ${hidden ? 'scale-0' : 'scale-100'}`} style={{transitionTimingFunction: "cubic-bezier(0.64, 0.57, 0.67, 1.53)"}}>
    {children}
  </span>;
};