import type { ReactNode} from "react";
import { type FC } from "react";

type Props = {
  children: ReactNode,
  className?: string,
  id?: string
}

/**
 * Permet d'afficher un message d'erreur d'un input
 */
export const InputErrorMessage: FC<Props> = function({id, children, className = ""}) {
  return <span id={id} className={`text-sm block text-error ${className} mt-1`} role="alert">{children}</span>;
};