import { type FC } from 'react';
import { convertPrice } from './hooks/use-rendered-price';
interface Props {
  suffixLabel?: string;
  /**
   * Le prix de base sans promotion
   */
  price: number;
  /**
   * Le prix en promotion. Si jamais il est définit, il sera affiché à la place du prix de base
   */
  discountedPrice?: number | undefined | null;
  showFirstMonth?: boolean;
  freeLabel?: string;
  className?: string;
  inline?: boolean;
}

/**
 * Affiche le prix d'un produit 
 * 
 * Si un `discountedPrice` est donné, alors le prix affichera `price` en prix barré
 */
const Price: FC<Props> = function (props) {
  // If there is a discountedPrice, we use it over the price
  const currentPrice = props.discountedPrice ?? props.price;

  // Checks if the price is currently free or not
  const isFree = currentPrice <= 0;

  const inline = props.inline ?? false;
  
  return (
    <span className={`leading-none text-right ${props.className ?? ''}`}>
      {/* if discountedPrice, show regular price as a strokedPrice */}
      {props.discountedPrice != null && (
        <span className={`${inline ? 'leading-[inherit] align-baseline inline-block mr-2' : 'block align-text-top leading-[0.6]'} opacity-75 text-xs text-light line-through`}>
          {convertPrice(props.price)} €{props.suffixLabel && <> {props.suffixLabel}</>}
        </span>
      )}

      {/* Actual price : discounted or regular depending of the context */}
      <span className={inline ? '' : 'block'}>
        {isFree && props.freeLabel ? (
          props.freeLabel
        ) : (
          <>
            {convertPrice(currentPrice)} €
            {props.suffixLabel && <span className="text-xs"> {props.suffixLabel}</span>}
          </>
        )}

        {props.showFirstMonth && props.discountedPrice != null && (
          <span className="text-xs inline-block ml-1">le 1<span className="align-super text-[0.8em]">er</span> mois</span>
        )}
      </span>

    </span>
  );
};

export { Price };
