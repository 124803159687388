import { type FC } from 'react';
import { LoadingIndicator } from './loading-indicator';

type Props = {
  show: boolean
}

export const LoadingOverlay: FC<Props> = function ({show}) {
  if (!show) return null;

  return (
    <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/75 animate-in fade-in-0">
      <div className='animate-in zoom-in-0 text-light flex items-center'>
        <LoadingIndicator className="text-light" /> Chargement...
      </div>
    </div>
  );
};
