"use client"

import * as DialogPrimitive from "@radix-ui/react-dialog"

import { cn } from "@web/lib/utils"
import { cva, type VariantProps } from "class-variance-authority"
import { FC, forwardRef, ReactNode, useCallback, useState } from "react"

const Dialog = DialogPrimitive.Root

type DialogController = {
  isOpen: boolean,
  open: () => void,
  close: () => void,
  toggle: () => void,
}

type DialogProps = {
  dismissible?: boolean,
  defaultOpen?: boolean,
  children: (controller: DialogController) => ReactNode,
}

const ControlledDialog: FC<DialogProps> = function ({children, dismissible = true, defaultOpen = false}) {
  const [isOpen, setOpen] = useState<boolean>(defaultOpen);
  const open = useCallback(() => { setOpen(true) }, []);
  const close = useCallback(() => { setOpen(false) }, []);
  const toggle = useCallback(() => { setOpen(o => !o) }, []);

  const controller = {
    isOpen,
    open,
    close,
    toggle,
  };

  return <DialogPrimitive.Root open={isOpen} onOpenChange={(change) => {
    if (!dismissible) return;
    setOpen(change);
  }}>
    {children(controller)}
  </DialogPrimitive.Root>

}

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "overlay fixed cursor-pointer inset-x-0 top-[var(--menu-offset)] bottom-0 z-50 bg-black/40 backdrop-blur-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

type DialogContentProps = {
  position: ""
}

const dialogContentWrapperStyles = cva("fixed !pointer-events-none inset-x-0 bottom-0 top top-[var(--menu-offset)] flex z-50 p-6 duration-200 group data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 sm:rounded-lg", {
  variants: {
    position: {
      center: "items-center justify-center",
      "top-left": "items-start justify-start",
      "top-right": "items-start justify-end",
    },
  }
});

const dialogContentStyles = cva("w-full max-w-lg bg-white rounded-lg shadow-xl p-6 relative overflow-y-auto pointer-events-all duration-200 group-data-[state=open]:animate-in group-data-[state=closed]:animate-out group-data-[state=closed]:zoom-out-95 group-data-[state=open]:zoom-in-95");


const DialogContent = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.PropsWithChildren & VariantProps<typeof dialogContentWrapperStyles>
>(({ children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={dialogContentWrapperStyles(props)}
    >
      <div className={dialogContentStyles()}>
        {children}
      </div>
    </DialogPrimitive.Content>
  </DialogPortal>
))
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    )}
    {...props}
  />
)
DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

const DialogTitle = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  ControlledDialog
}
